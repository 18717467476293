import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { useSafeUpdateQuery } from '../../use-safe-update-query'

import { CtaButton } from './cta-button'
import { ParallaxElement } from './parallax-element'
import { SellingPoints } from './selling-points'

function Money() {
  return (
    <>
      <ParallaxElement
        delay="0.2"
        position="absolute"
        width="60px"
        top={['5%', '18%', '18%', '18%']}
        left={['0%', '28%']}
        style={{ pointerEvents: 'none' }}
        alt="money"
        src="/images/paradise/small_money.png"
      />
      <ParallaxElement
        delay="0.3"
        position="absolute"
        width={['130px', '190px']}
        top="38%"
        left={['-10%', '12%', '12%', '12%']}
        style={{ pointerEvents: 'none' }}
        alt="money"
        src="/images/paradise/money2.png"
      />
      <ParallaxElement
        delay="0.1"
        position="absolute"
        width={['100px', '150px']}
        top="30%"
        right={['-15%', '10%', '10%', '10%']}
        transformStyle="scaleX(-1)"
        style={{ pointerEvents: 'none' }}
        alt="money"
        src="/images/paradise/money2.png"
      />
    </>
  )
}

export function HeroSection() {
  const registerQuery = useSafeUpdateQuery({ register: 'me' })
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      pt={7}
      px={2}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Money />
      <Common.Box
        as="p"
        textAlign="center"
        color="#114164"
        fontSize={['18px', '22px', '22px']}
        fontWeight="400"
        maxWidth={['300px', null, '100%']}
        style={{ fontFamily: 'gilroylight' }}
      >
        {i18n.translate('landing-page-3.subtitle')}
      </Common.Box>
      <Common.Box
        textAlign="center"
        pt={[1, 0, 2]}
        color="#040E2F"
        style={{ fontFamily: 'Grobold' }}
        fontSize={['35px', '48px', '48px']}
        fontWeight={['400', '500']}
        as="h1"
        maxWidth={['300px', '500px', '500px']}
        lineHeight="normal"
      >
        {i18n.translate('landing-page-3.title')}
      </Common.Box>
      <Common.Box pt={['10px', '0px']} display="flex" flexDirection="row">
        <Common.Box
          as="p"
          color="#0065A5"
          pt={1}
          style={{
            textTransform: 'uppercase',
            fontFamily: 'gilroyextrabold',
          }}
          fontSize={['21px', '28px']}
          fontWeight={['300', '600']}
        >
          {i18n.translate('landing-page-3.bonus-amount')}
        </Common.Box>
        <Common.Box
          as="p"
          color="#0065A5"
          pt={1}
          style={{
            textTransform: 'uppercase',
            fontFamily: 'gilroyextrabold',
          }}
          fontSize={['21px', '28px']}
          fontWeight={['400', '600']}
        >
          {i18n.translate('landing-page-3.spin-amount')}
        </Common.Box>
      </Common.Box>
      <Common.Box pt={4}>
        <CtaButton to={`?${registerQuery}`}>
          {i18n.translate('landing-page.banner.cta')}
        </CtaButton>
      </Common.Box>
      <SellingPoints />
    </Common.Box>
  )
}
