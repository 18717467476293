import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as I18n from '@rushplay/i18n'

import { Button } from '../button'
import { useSafeUpdateQuery } from '../use-safe-update-query'

export function ManualSignUp() {
  const manualSignupEnabled = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )
  const i18n = I18n.useI18n()
  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const registerQuery = useSafeUpdateQuery({ register: 'me' })

  if (!manualSignupEnabled) {
    return null
  }

  return (
    <Common.Box p={[0, 2, 2]} mx="auto" textAlign="center">
      <Common.Box>
        <ReactRouter.Link
          to={`?${registerQuery}`}
          data-testid="landing-page.register-button"
        >
          <Button padding="1.7em 1.7em" stretch variant="secondary">
            <Common.Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Common.Box
                style={{
                  lineHeight: '20px',
                }}
                fontSize={['18px', '24px', '24px']}
              >
                {i18n.translate('landing-page.register')}
              </Common.Box>
            </Common.Box>
          </Button>
        </ReactRouter.Link>
      </Common.Box>

      <Common.Box
        pt={2}
        pb={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        fontWeight="normal"
        width="100%"
      >
        <ReactRouter.Link
          to={`?${loginQuery}`}
          data-testid="landing-page.login-button"
          style={{ textDecoration: 'underline' }}
        >
          {i18n.translate('landing-page.login.cta')}
        </ReactRouter.Link>
      </Common.Box>
    </Common.Box>
  )
}
