import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import { Banner } from './landing-page-2/banner'
import { BottomSection } from './landing-page-2/bottom-section'
import { HeroSection } from './landing-page-2/hero-section'
import { LandingPageLayout } from './landing-page-default-layout'
import { PaymentProviders } from './landing-page-2/payment-providers'

const LandingPageContainer = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: auto;
`

const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  z-index: 99;

  @media (min-width: 800px) {
    transform: translateY(-50%);
  }
`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: auto;
  position: relative;
  ${props => `background-image: url(${props.src});`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

function PrettyClouds() {
  return (
    <>
      <Common.Box
        style={{ pointerEvents: 'none', mixBlendMode: 'screen' }}
        position="absolute"
        top={['-5%', '5%']}
        left={['-40%', '-10%']}
        as="img"
        alt="cloud"
        src="/images/paradise/Cloud left.png"
      ></Common.Box>
      <Common.Box
        style={{
          pointerEvents: 'none',
          mixBlendMode: 'screen',
          transform: 'translateY(0)',
        }}
        position="absolute"
        top={['-5%', '5%']}
        right={['-40%', '-10%']}
        as="img"
        alt="cloud"
        src="/images/paradise/Cloud right.png"
      ></Common.Box>
    </>
  )
}

export function LandingPage() {
  return (
    <LandingPageLayout>
      <LandingPageContainer>
        <ContentWrapper src="/images/paradise/bg.svg">
          <PrettyClouds />
          <HeroSection />
        </ContentWrapper>
        <BottomSection />
      </LandingPageContainer>
      <BannerWrapper>
        <Banner />
      </BannerWrapper>
      <PaymentProviders />
    </LandingPageLayout>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
