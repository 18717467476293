import * as React from 'react'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

const Human = styled.img`
  pointer-events: none;
  width: ${props => props.width[2] || '100%'};
  position: relative;
  z-index: 99;
  min-width: ${props => props.minWidth[2] || '400px'};

  @media (max-width: 800px) {
    position: absolute;
    right: 0%;
    bottom: 0%;

    left: ${props => props.left[1] || '0%'};
    width: ${props => props.width[1] || '80%'};
    min-width: ${props => props.minWidth[1] || '300px'};
  }

  @media (max-width: 500px) {
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 10%;
    min-width: ${props => props.minWidth[0] || '300px'};
    transform: ${props =>
      props.transform ? `${props.transform}` : 'translate(-50%, -50%)'};
    z-index: 1;
  }
`

/**
 * Renders an image of a man and a woman within a grid layout.
 * @returns {JSX.Element} The component rendering images of a man and a woman.
 */
function Humans() {
  return (
    <Common.Box
      width="100%"
      display="grid"
      gridColumnGap={['0px', '10px']}
      gridTemplateColumns="repeat(2, 1fr)"
      alignItems="flex-end"
      zIndex={['1', '99']}
    >
      <Human
        as="img"
        src="/images/paradise/Man.png"
        alt="Man"
        left={['50%', '0%']}
        width={['100%', '100%', '100%']}
        zIndex={['1', '99']}
        justifySelf="end"
        transform="translate(-30%, -40%)"
        minWidth={['380px', '450px', '480px']}
      ></Human>
      <Human
        as="img"
        src="/images/paradise/Girl.png"
        alt="Woman"
        left={['50%', '30%', '0%']}
        width={['80%', '70%', '80%']}
        zIndex={['1', '99']}
        justifySelf="start"
        transform="translate(-80%, -50%)"
        minWidth={['300px', '380px', '400px']}
      ></Human>
    </Common.Box>
  )
}

function Trees() {
  return (
    <>
      <Common.Box
        width="450px"
        as="img"
        src="/images/paradise/Palm big.png"
        position="absolute"
        left={['-29%', '-10%', '0%']}
        top={['-110%', '-40%', '-40%']}
        style={{ pointerEvents: 'none' }}
        alt="palm"
      ></Common.Box>
      <Common.Box
        width="650px"
        as="img"
        src="/images/paradise/Palm right.png"
        position="absolute"
        right={['-70%', '0%']}
        top="-50%"
        style={{ pointerEvents: 'none' }}
        alt="palm"
      ></Common.Box>
    </>
  )
}

// custom media query
const Wrapper = styled(Common.Box)`
  background-image: url('/images/paradise/villa.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #a6d4f0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background-size: cover;
  height: 100vh;
  min-height: 700px;
  width: 100%;
  display: flex;

  @media (max-width: 500px) {
    background-image: none;
  }
`

const VillaMobile = styled(Common.Box)`
  display: none;
  background-image: url('/images/paradise/villa-mobile.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  height: 70vh;
  position: absolute;
  max-height: 1000px;
  z-index: 9;
  bottom: 0%;
  width: 100%;
  left: 0%;
  min-height: 600px;

  @media (max-width: 500px) {
    display: block;
  }
`

/**
 * Renders the bottom section of the page with a background image and the `Humans` component.
 * @returns {JSX.Element} The component rendering the bottom section.
 */
export function BottomSection() {
  return (
    <Wrapper>
      <Humans />
      <Trees />
      <VillaMobile />
    </Wrapper>
  )
}
