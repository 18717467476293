import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

/**
 * ParallaxElement component renders an image with a parallax effect based on scroll position.
 *
 * @component
 * @example
 * // Example usage of ParallaxElement
 * <ParallaxElement alt="Example Alt" src="example.jpg" delay={0.7} transformStyle="scale(1.2)" />
 *
 * @param {Object} props - The properties of the ParallaxElement component.
 * @param {string} props.alt - The alternative text for the image.
 * @param {string} props.src - The source URL of the image.
 * @param {number} [props.delay=0.5] - The delay factor for the parallax effect. Defaults to 0.5.
 * @param {string} [props.transformStyle] - The additional CSS transform style for the image.
 * @returns {JSX.Element} JSX element representing the ParallaxElement component.
 */
export function ParallaxElement(props) {
  const parallaxImageRef = React.useRef(null)
  const factor = Number(props.delay) || 0.5

  React.useEffect(() => {
    /**
     * Event handler for the 'scroll' event to update the parallax effect based on scroll position.
     */
    function handleScroll() {
      const scrollPosition = window.scrollY
      if (parallaxImageRef.current) {
        parallaxImageRef.current.style.transform = `translateY(${scrollPosition *
          factor}px)`
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Common.Box
      as="img"
      alt={props.alt}
      src={props.src}
      zIndex="9"
      {...props}
      ref={parallaxImageRef}
    ></Common.Box>
  )
}

ParallaxElement.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  delay: PropTypes.number,
  transformStyle: PropTypes.string,
}
