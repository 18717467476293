import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import * as Configuration from '../configuration'
import { CurrencyProviderImages } from '../currency-providers'
import { HtmlContent } from '../html-content'
import { InformativeSection } from '../informative-section'
import { LiveCasinoGameDisplay } from '../live-casino-game-display-boom'
import { useTranslationImgproxyUrl } from '../use-imgproxy-url'

import { ManualSignUp } from './manual-signup'

const LandingPageCategory = loadable(() => import('../landing-page-category'))

export function LandingPage(props) {
  const i18n = I18n.useI18n()
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  const background = useTranslationImgproxyUrl(
    i18n.translate('landing-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '1200',
    }
  )

  const backgroundMobile = useTranslationImgproxyUrl(
    i18n.translate('landing-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '950',
    }
  )

  return (
    <Common.Box display="flex" flexDirection="column" width="100%">
      <Common.Box
        backgroundImage={[`url(${backgroundMobile})`, `url(${background})`]}
        backgroundPosition={['top', 'left']}
        backgroundSize={'cover'}
        backgroundRepeat="no-repeat, no-repeat, no-repeat"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        style={{
          // ios fix
          height: 'intrinsic',
        }}
      >
        <Common.Box
          fontWeight="bold"
          textShadow="0px 0px 10px rgba(255, 255, 255, 0.93)"
          mt={[2, 5]}
          mb="8px"
          mx="5%"
          letterSpacing="-.03em"
          border="1px solid white"
          borderRadius="25px"
          p="24px"
          px={['20px', '30px', '50px', '50px']}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          background="#ffffff70"
        >
          <Common.Box
            fontSize={'22px'}
            mb={['16px', 1]}
            letterSpacing="0.08em"
            fontWeight="800"
            pt={3}
          >
            {i18n.translate('landing-page.bonus.heading')?.toUpperCase()}
          </Common.Box>
          <Common.Box
            fontSize={['100px', '120px', '120px', '130px']}
            lineHeight={['100px', '112px']}
            letterSpacing="-.04em"
            fontFamily="Outfit"
          >
            {i18n.translate('landing-page.bonus-amount', {
              amount: props.maxBonusAmount,
            })}
          </Common.Box>
          <Common.Box
            fontSize={['17px', '20px', '20px', '20px']}
            mt={['8px', 1]}
            mb={['8px', 1]}
          >
            {i18n.translate('landing-page.free-spins', {
              freespins: props.maxFixedFreespins,
            })}
          </Common.Box>
          <Common.Box display="flex" alignItems="center" margin="auto">
            <ManualSignUp />
          </Common.Box>
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          style={{ borderBottom: '1px solid #ffffff1c' }}
          pt={3}
          pb={3}
          px={2}
        >
          <CurrencyProviderImages providers={paymentProviders} />
        </Common.Box>
      </Common.Box>
      <Common.Box maxWidth="1080px" width="100%" mx="auto">
        <LandingPageCategory />
        <LiveCasinoGameDisplay />
      </Common.Box>
      <Common.Box maxWidth="1080px" alignSelf="center">
        <InformativeSection />
      </Common.Box>
      <Common.Box
        p={2}
        maxWidth="1080px"
        mx="auto"
        textAlign="left"
        fontSize={2}
      >
        <HtmlContent
          html={{
            __html: i18n.translate('landing-page.seo-content'),
          }}
        />
      </Common.Box>
    </Common.Box>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
