import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import { CurrencyProviderImages } from '../currency-providers'
import { useSafeUpdateQuery } from '../use-safe-update-query'

import LandingPageLayout from './landing-page-default-layout'

const SignUpButton = styled(ReactRouter.Link)`
  border-radius: 4px;
  background: #36ace0;
  box-shadow: 0px 15px 33.3px -5px rgba(0, 0, 0, 0.38);
  color: white;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 12px 55px;
`

const UspNumber = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  background: white;
  color: #114164;
`

const LandingPageContainer = styled.div`
  position: relative;
  ${props =>
    props.background ? `background-image: url(${props.background});` : null}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  height: intrinsic;

  @media (min-width: 1500px) {
    min-height: calc(100vh - 30em);
    background-position: center 70%;
  }

  @media (min-width: 768px) {
    min-height: 600px;
  }

  @media (min-width: 992px) {
    min-height: 700px;
  }
`

export function LandingPage() {
  const i18n = I18n.useI18n()
  const registerQuery = useSafeUpdateQuery({ register: 'me' })

  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <LandingPageLayout>
      <LandingPageContainer
        background={i18n.translate('landing-page.default-background')}
      >
        <Common.Box
          position="absolute"
          top="0px"
          left="0px"
          style={{ pointerEvents: 'none', zIndex: '0' }}
          background="linear-gradient(1deg,rgba(255,255,255,0.00) 5.94%,rgba(255,255,255,0.49) 64.58%,#FFF 100%)"
          width="100%"
          height="390px"
        ></Common.Box>
        <Common.Box
          zIndex="99"
          width="100%"
          maxWidth="1400px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          marginTop={['90px', '170px', '190px']}
        >
          <Common.Box
            textAlign="center"
            color="#114164"
            style={{ fontFamily: 'Grobold' }}
            fontSize={['40px', '48px', '48px']}
            fontWeight="500"
            as="h1"
            maxWidth={['500px', null, '100%']}
          >
            {i18n.translate('landing-page.title')}
          </Common.Box>
          <Common.Box
            as="p"
            textAlign="center"
            pt={[1, 0, 0]}
            color="#114164"
            fontSize={['18px', '22px', '22px']}
            fontWeight="400"
            maxWidth={['300px', null, '100%']}
          >
            {i18n.translate('landing-page.subtitle')}
          </Common.Box>
          <Common.Box pt={4}>
            <SignUpButton
              background="additional-button"
              to={`?${registerQuery}`}
            >
              {i18n.translate('landing-page.register')}
            </SignUpButton>
          </Common.Box>
        </Common.Box>

        <Common.Box
          background="linear-gradient(180deg, rgba(255, 255, 255, 0.00) 5.94%, rgba(17, 65, 100, 0.50) 36.98%, #114164 64.58%)"
          width="100%"
          height="165px"
          position="absolute"
          bottom="0px"
          left="0px"
          style={{
            pointerEvents: 'none',
          }}
        ></Common.Box>
      </LandingPageContainer>
      <Common.Box
        position="relative"
        display="flex"
        py={7}
        width="100%"
        px={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        background="#104164"
      >
        <Common.Box
          position="absolute"
          top={['-100px', '-130px', '-130px']}
          left="0"
          color="white"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Common.Box
            as="p"
            fontSize="18px"
            style={{ textTransform: 'uppercase', letterSpacing: '6.3px' }}
            textAlign="center"
            pt={0}
            fontWeight="400"
          >
            {i18n.translate('landing-page.bonus.heading')}
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box
              width="50px"
              fontSize={['25px', '32px', '32px']}
              fontWeight="700"
              as="p"
              style={{ textTransform: 'uppercase', lineHeight: '1' }}
            >
              {i18n.translate('landing-page.bonus.subtitle')}
            </Common.Box>
            <Common.Box
              as="p"
              style={{ textTransform: 'uppercase' }}
              fontSize={['76px', '84px', '84px']}
              fontWeight="700"
            >
              {i18n.translate('landing-page.bonus-amount')}
            </Common.Box>
          </Common.Box>
          <Common.Box
            as="p"
            fontSize="18px"
            style={{ textTransform: 'uppercase', letterSpacing: '2.88px' }}
            textAlign="center"
            fontWeight="400"
          >
            {i18n.translate('landing-page.free-spins')}
          </Common.Box>
        </Common.Box>
        <Common.Box
          width="100%"
          maxWidth="1440px"
          background="linear-gradient(180deg, rgba(255, 255, 255, 0.00) 5.94%, rgba(17, 65, 100, 0.50) 36.98%, #114164 64.58%)"
        ></Common.Box>
        <Common.Box
          as="h3"
          color="white"
          fontSize="28px"
          fontWeight="700"
          fontfamily="Inter"
          textAlign="center"
        >
          {i18n.translate('landing-page.seling-point.title')}
        </Common.Box>
        <Common.Box
          as="p"
          pt={1}
          maxWidth="620px"
          textAlign="center"
          fontSize="16px"
          color="white"
          fontWeight="400"
          fontFamily="inter"
        >
          {i18n.translate('landing-page.seling-point.subtitle')}
        </Common.Box>
        <Common.Box
          style={{ gridRowGap: '30px' }}
          maxWidth="900px"
          mt={6}
          width="auto"
          display="grid"
          justifyItems={['flex-start', 'center', 'center', 'center']}
          gridTemplateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 240px)',
          ]}
        >
          <Common.Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <UspNumber>1</UspNumber>
            <Common.Box
              pl={0}
              width={['auto', '145px']}
              color="white"
              fontSize="16px"
              fontWeight="200"
              fontFamily="inter"
              style={{ textTransform: 'uppercase', lineHeight: '1.4' }}
            >
              {i18n.translate('landing-page.seling-point.step-1')}
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
          >
            <UspNumber>2</UspNumber>
            <Common.Box
              pl={0}
              width={['auto', '145px']}
              color="white"
              fontSize="16px"
              fontWeight="200"
              fontFamily="inter"
              style={{ textTransform: 'uppercase', lineHeight: '1.4' }}
            >
              {i18n.translate('landing-page.seling-point.step-2')}
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
          >
            <UspNumber>3</UspNumber>
            <Common.Box
              pl={0}
              width={['auto', '145px']}
              color="white"
              fontSize="16px"
              fontWeight="200"
              fontFamily="inter"
              style={{ textTransform: 'uppercase', lineHeight: '1.4' }}
            >
              {i18n.translate('landing-page.seling-point.step-3')}
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <UspNumber>4</UspNumber>
            <Common.Box
              pl={0}
              width={['auto', '145px']}
              color="white"
              fontSize="16px"
              fontWeight="200"
              fontFamily="inter"
              style={{ textTransform: 'uppercase', lineHeight: '1.4' }}
            >
              {i18n.translate('landing-page.seling-point.step-4')}
            </Common.Box>
          </Common.Box>
        </Common.Box>
      </Common.Box>
      {paymentProviders?.lenght > 0 ? (
        <Common.Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          background="white"
          pt={3}
          pb={3}
          px={2}
        >
          <CurrencyProviderImages providers={paymentProviders} />
        </Common.Box>
      ) : null}
    </LandingPageLayout>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
