import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import { HtmlContent } from '../html-content'
import { InformativeSection } from '../informative-section'
import { LiveCasinoGameDisplay } from '../live-casino-game-display-boom'

const LandingPageCategory = loadable(() => import('../landing-page-category'))

export function LandingPageLayout(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      overflow="hidden"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {props.children}
      <Common.Box mt={2} maxWidth="1080px" width="100%" mx="auto">
        <LandingPageCategory />
        <LiveCasinoGameDisplay />
      </Common.Box>
      <Common.Box maxWidth="1080px" alignSelf="center">
        <InformativeSection />
      </Common.Box>
      <Common.Box
        p={2}
        maxWidth="1080px"
        mx="auto"
        textAlign="left"
        fontSize={2}
      >
        <HtmlContent
          html={{
            __html: i18n.translate('landing-page.seo-content'),
          }}
        />
      </Common.Box>
    </Common.Box>
  )
}

LandingPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

// for @loadable/components
export default LandingPageLayout
