import * as ReactRouter from 'react-router-dom'

import styled from '@emotion/styled'

export const CtaButton = styled(ReactRouter.Link)`
  color: #252800;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  ${props =>
    props.borderRadius
      ? `border-radius: ${props.borderRadius};`
      : 'border-radius: 98px;'}
  border: 1px solid #feffe1;
  background: linear-gradient(90deg, #fbff21 0%, #fdff9f 100%);
  padding: 15px 25px;
  cursor: pointer;
  z-index: 99999;
`
