import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

const UspNumber = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #040e2f;
  background: none;
  color: #040e2f;
`

export function SellingPoint(props) {
  return (
    <Common.Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      padding={'20px 18px'}
      background="#BCE4FF"
      borderRadius="25px"
      width={['100%', 'auto']}
    >
      <UspNumber>{props.number}</UspNumber>
      <Common.Box
        pl={0}
        width={['100%', '145px']}
        color=" #040E2F"
        fontSize={['14px', '16px']}
        fontWeight="200"
        fontFamily="inter"
        textAlign="center"
        maxWidth={['120px', '200px']}
        style={{ textTransform: 'uppercase', lineHeight: '1.4' }}
      >
        {props.text}
      </Common.Box>
    </Common.Box>
  )
}

SellingPoint.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
}

export function SellingPoints() {
  const i18n = I18n.useI18n()
  return (
    <Common.Box
      style={{ gridRowGap: '30px' }}
      mt={6}
      width={['100%', 'auto']}
      maxWidth={['400px', '100%']}
      display="grid"
      gridGap="30px"
      zIndex="9"
      justifyItems={['flex-start', 'center', 'center', 'center']}
      gridTemplateColumns={[
        '1fr',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)',
      ]}
    >
      <SellingPoint
        number={1}
        text={i18n.translate('landing-page-3.selling-point.1')}
      />
      <SellingPoint
        number={2}
        text={i18n.translate('landing-page-3.selling-point.2')}
      />
      <SellingPoint
        number={3}
        text={i18n.translate('landing-page-3.selling-point.3')}
      />
      <SellingPoint
        number={4}
        text={i18n.translate('landing-page-3.selling-point.4')}
      />
    </Common.Box>
  )
}
