import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { LandingPageLayout } from './landing-page-default-layout'
import { PaymentProviders } from './landing-page-2/payment-providers'

const LandingPageContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: auto;
`
// Not implemented
export function LandingPage() {
  return (
    <LandingPageLayout>
      <LandingPageContainer>Not Found</LandingPageContainer>
      <PaymentProviders />
    </LandingPageLayout>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
