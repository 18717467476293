import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'
import { lazy as loadable } from '@loadable/component'

import * as Configuration from '../configuration'
import { CurrencyProviderImages } from '../currency-providers'
import { HtmlContent } from '../html-content'
import { InformativeSection } from '../informative-section'
import { LiveCasinoGameDisplay } from '../live-casino-game-display-boom'
import { useTranslationImgproxyUrl } from '../use-imgproxy-url'

import { ManualSignUp } from './manual-signup'

const LandingPageCategory = loadable(() => import('../landing-page-category'))

const OutlinedText = styled.div`
  font-family: Poppins;
  font-size: 57px;
  color: white;
  background: linear-gradient(
    180deg,
    #feffba 50%,
    #e9782f 51.69%,
    #fffc5e 131.25%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px 2px #060606b8);
  position: relative;

  ${props =>
    props.text &&
    `  &:after{
      content: '${props.text}';
      position: absolute;
      -webkit-text-stroke: 2px rgb(183 47 27);
      top:0;
      left:0;
    }
  `}

  ${props =>
    props.text &&
    `  &:before{
        content: '${props.text}';
        position: absolute;
        -webkit-text-stroke: 4px rgb(255 201 68);
        top:0;
        left:0;
      }
    `}
`

function Bullet(props) {
  return (
    <Common.Box
      position="relative"
      width="30px"
      height="30px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="white"
      fontSize="21px"
      fontWeight="700"
      backgroundImage="url('/images/bullet.svg')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      {props.children}
    </Common.Box>
  )
}

Bullet.propTypes = {
  children: PropTypes.node,
}

export function LandingPage(props) {
  const i18n = I18n.useI18n()
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  const background = useTranslationImgproxyUrl(
    i18n.translate('landing-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '1200',
    }
  )

  const backgroundMobile = useTranslationImgproxyUrl(
    i18n.translate('landing-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '950',
    }
  )

  return (
    <Common.Box display="flex" flexDirection="column" width="100%">
      <Common.Box
        backgroundImage={[`url(${backgroundMobile})`, `url(${background})`]}
        backgroundSize={'cover'}
        backgroundRepeat="no-repeat, no-repeat, no-repeat"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        position="relatove"
        style={{
          // ios fix
          height: 'intrinsic',
        }}
      >
        <Common.Box
          fontWeight="bold"
          mt={6}
          mb="8px"
          mx="5%"
          p="10px"
          maxWidth="360px"
          border="1px solid white"
          borderRadius="25px"
          background="#ffffff70"
          position="relative"
        >
          <Common.Box
            position="absolute"
            top="-1%"
            right="50%"
            left="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="11"
            width="387px"
            style={{
              transform: 'translate(-50%, -50%)',
            }}
            backgroundImage="url('/images/ribbon.png')"
            height="75px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          >
            <Common.Box
              color="white"
              width="100%"
              fontSize="15px"
              fontWeight="400"
              textAlign="center"
              pb="14px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              maxWidth="225px"
              lineHeight="20px"
              style={{
                wordBreak: 'break-all',
                whiteSpace: '20px',
              }}
            >
              {i18n.translate('landing-page.ribbon-text')}
            </Common.Box>
          </Common.Box>

          <Common.Box
            letterSpacing="-.03em"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            borderRadius="25px"
            background="white"
            width="340px"
            height="100%"
            px={['17px']}
            pt={4}
            pb={2}
          >
            <Common.Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pb={'12px'}
            >
              <Common.Box
                display="flex"
                width="100%"
                position="relative"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Bullet>1</Bullet>
                <Common.Box as="p" fontSize="19px" fontWeight="700" pl={0}>
                  {i18n.translate('landing-page.bullet-one')}
                </Common.Box>
              </Common.Box>
              <OutlinedText
                text={i18n.translate('landing-page.bonus-amount', {
                  amount: props.maxBonusAmount,
                })}
              >
                {i18n.translate('landing-page.bonus-amount', {
                  amount: props.maxBonusAmount,
                })}
              </OutlinedText>

              <Common.Box
                width="100%"
                height="1px"
                mt={'12px'}
                background="linear-gradient(270deg, rgba(244, 239, 235, 0.00) 0%, #F8EDE5 52.08%, rgba(244, 239, 235, 0.00) 100%)"
              />
            </Common.Box>

            <Common.Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pb={'12px'}
            >
              <Common.Box
                display="flex"
                width="100%"
                position="relative"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Bullet>2</Bullet>
                <Common.Box as="p" fontSize="19px" fontWeight="700" pl={0}>
                  {i18n.translate('landing-page.bullet-two')}
                </Common.Box>
              </Common.Box>
              <OutlinedText
                text={i18n.translate('landing-page.maximum-amount')}
              >
                {i18n.translate('landing-page.maximum-amount')}
              </OutlinedText>

              <Common.Box
                width="100%"
                height="1px"
                mt={'12px'}
                mb={0}
                background="linear-gradient(270deg, rgba(244, 239, 235, 0.00) 0%, #F8EDE5 52.08%, rgba(244, 239, 235, 0.00) 100%)"
              />
            </Common.Box>

            <Common.Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pb={'12px'}
            >
              <Common.Box
                display="flex"
                width="100%"
                position="relative"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Bullet>3</Bullet>
                <Common.Box as="p" fontSize="19px" fontWeight="700" pl={0}>
                  {i18n.translate('landing-page.bullet-three')}
                </Common.Box>
              </Common.Box>

              <Common.Box
                width="100%"
                height="1px"
                mt={'12px'}
                background="linear-gradient(270deg, rgba(244, 239, 235, 0.00) 0%, #F8EDE5 52.08%, rgba(244, 239, 235, 0.00) 100%)"
              />
            </Common.Box>

            <Common.Box display="flex" alignItems="center" margin="auto">
              <ManualSignUp />
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box
          py={2}
          wdth="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Common.Box fontSize="14px" fontWeight="500">
            {i18n.translate('landing-page.made-by')}
          </Common.Box>
          <Common.Box width="104px" height="52px">
            <img
              width="100%"
              height="100%"
              src="/images/logo-casitabi.svg"
              alt="casitabi"
              loading="lazy"
            />
          </Common.Box>
        </Common.Box>
        {paymentProviders?.length > 0 ? (
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            style={{ borderBottom: '1px solid #ffffff1c' }}
            pt={3}
            pb={3}
            px={2}
          >
            <CurrencyProviderImages providers={paymentProviders} />
          </Common.Box>
        ) : null}
      </Common.Box>
      <Common.Box maxWidth="1080px" width="100%" mx="auto">
        <LandingPageCategory />
        <LiveCasinoGameDisplay />
      </Common.Box>
      <Common.Box maxWidth="1080px" alignSelf="center">
        <InformativeSection />
      </Common.Box>
      <Common.Box
        p={2}
        maxWidth="1080px"
        mx="auto"
        textAlign="left"
        fontSize={2}
      >
        <HtmlContent
          html={{
            __html: i18n.translate('landing-page.seo-content'),
          }}
        />
      </Common.Box>
    </Common.Box>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
