import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'

import * as Configuration from '../../configuration'
import { CurrencyProviderImages } from '../../currency-providers'

export function PaymentProviders() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  if (!paymentProviders || paymentProviders.length === 0) {
    // add spacing under the banner even if there are no providers
    return <Common.Box pt={[6, 3]}></Common.Box>
  }

  return (
    <Common.Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      backgroundColor="payment-provider-background"
      pt={[6, 3]}
      pb={3}
      px={2}
    >
      <CurrencyProviderImages providers={paymentProviders} />
    </Common.Box>
  )
}
