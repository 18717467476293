import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import { useSafeUpdateQuery } from '../../use-safe-update-query'

import { CtaButton } from './cta-button'

const EnhancedCommon = styled.img`
  position: absolute;
  pointer-events: none;
  z-index: ${props => props.zIndex || 'auto'};
  bottom: ${props => props.bottom[2] || props.bottom[1] || 'auto'};
  right: ${props => props.right[2] || props.right[1] || 'auto'};
  left: ${props => props.left[2] || props.left[1] || 'auto'};
  width: ${props => props.width[2] || props.width[1] || 'auto'};

  @media screen and (max-width: 800px) {
    width: ${props => props.width[0] || 'auto'};
    right: ${props => props.right[0] || 'auto'};
    left: ${props => props.left[0] || 'auto'};
    transform: ${props => props.transform[0] || 'none'};
    transform: ${props => (props.transform ? `${props.transform}` : 'none')};
  }
`

function BannerImages() {
  return (
    <Common.Box display="flex" position="relative" width={['100%', 'auto']}>
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/helicopter.png"
        alt="Helicopter"
        width={['310px', '210px', '310px']}
        bottom={['3%', '-23%', '-23%']}
        right={['50%', '3%', '3%']}
        left={['50%', 'auto', 'auto']}
        transform="translate(-70%, -20%)"
        zIndex="99"
      ></EnhancedCommon>
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/yacht.png"
        alt="Yacht"
        width={['450px', '300px', '370px']}
        bottom={['50%', '-75%', '-70%']}
        zIndex="20"
        right={['50%', '-100%', '-83%']}
        left={['50%', 'auto', 'auto']}
        transform="translate(-47%, 30%)"
      ></EnhancedCommon>
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/dollars.png"
        alt="Dollars"
        width={['220px', '140px', '190px']}
        bottom={['0%', '40%', '40%']}
        zIndex={['99', '10', '10']}
        right={['50%', '-48%', '-48%']}
        left={['50%', 'auto', 'auto']}
        transform="translate(10%, -10%)"
      ></EnhancedCommon>
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/dollars.png"
        alt="Dollars"
        display={['block', 'none']}
        width={['220px', '140px', '190px']}
        bottom={['0%', '40%']}
        zIndex={'10'}
        right={['50%', '-48%']}
        left={['50%', 'auto', 'auto']}
        transform="translate(-120%, 10%)"
      ></EnhancedCommon>
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/palms-middle.png"
        alt="Palm"
        width={['310px', '280px', '310px']}
        bottom={['10%', '-35%', '10%']}
        zIndex="10"
        right={['50%', '-40%', '-20%']}
        left={['50%', 'auto', 'auto']}
        transform="translate(-20%, 0%)"
      />
      <EnhancedCommon
        position="absolute"
        as="img"
        src="/images/paradise/car.png"
        alt="Car"
        width={['320px', '240px', '320px']}
        bottom={['-40%', '-130%', '-120%']}
        transform="translate(-50%, 60%)"
        right={['50%', '-20%', '1%']}
        left={['50%', 'auto', 'auto']}
        zIndex="99"
      ></EnhancedCommon>
    </Common.Box>
  )
}

export function Banner() {
  const i18n = I18n.useI18n()
  const registerQuery = useSafeUpdateQuery({ register: 'me' })
  return (
    <Common.Box
      background="linear-gradient(90deg, #EC9007 0%, #FFC670 75%, #C97A03 100%)"
      width={['100%', '700px', '700px']}
      height={['calc(100vw + 230px)', '210px', '230px']}
      minHeight={['700px', '100px']}
      maxHeight="700px"
      borderRadius={['0px', '20px']}
      padding={['40px']}
      color="white"
      zIndex="99"
      display={['flex', 'grid']}
      gridTemplateColumns="60% 40%"
      justifyContent={['center', 'flex-start']}
      flexDirection={['column', 'row']}
    >
      <Common.Box
        display={'flex'}
        flexDirection="column"
        alignItems={['center', 'flex-start']}
        justifyContent={['flex-start']}
        position="relative"
        width="100%"
        height="100%"
      >
        <Common.Box
          width={['300px', '300px', '100%']}
          textAlign={['center', 'start']}
          as="h3"
          color="white"
          fontSize={['40px', '25px', '34px']}
          fontWeight="700"
        >
          {i18n.translate('landing-page.banner.title')}
        </Common.Box>
        <Common.Box
          as="p"
          color="white"
          fontSize={['16px', null, '16px']}
          pt={[2, 0, 2]}
          fontWeight="400"
          textAlign={['center', 'start']}
        >
          {i18n.translate('landing-page.banner.description')}
        </Common.Box>
        <Common.Box
          pt={['40px', '0px']}
          position={['relative', 'absolute']}
          bottom={['0%', '-28%']}
        >
          <CtaButton borderRadius="12px" to={`?${registerQuery}`}>
            {i18n.translate('landing-page.banner.cta')}
          </CtaButton>
        </Common.Box>
      </Common.Box>
      <BannerImages />
    </Common.Box>
  )
}
